<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document-callback'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('배팅 상세 내역(콜백)'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'detail/check',
      callbackUrl: this.$store.getters['auth/user'].detailCallbackUrl,
      response: '',
      body: {
        params: {
          type: 'json',
          value: JSON.stringify([{
            "id" : "1728973b55cddd3ba6f1513b",
            "gameProvider" : "evolution",
            "startedAt" : "2022-11-18T05:39:03.038Z",
            "settledAt" : "2022-11-18T05:40:24.373Z",
            "status" : "Resolved",
            "gameType" : "scalableblackjack",
            "table" : {
              "id" : "mrfykemt5slanyi5",
              "name" : "Infinite Blackjack"
            },
            "dealer" : {
              "uid" : "tts124765_______",
              "name" : "Agnese"
            },
            "currency" : "KRW",
            "participants" : [
              {
                "casinoId" : "skylinekgon00002",
                "playerId" : "tss_11001",
                "screenName" : "kim1004",
                "playerGameId" : "1728973b55cddd3ba6f1513b-qr5kapucv74dxzpn",
                "sessionId" : "qr5kapucv74dxzpnqset4gkxyvb5vhbx883fb9ca",
                "casinoSessionId" : "f407c2507afd99729b5025b88a8aefe5",
                "currency" : "KRW",
                "bets" : [
                  {
                    "code" : "SBJ_21_plus_3",
                    "stake" : 1000,
                    "payout" : 0,
                    "placedOn" : "2022-11-18T05:39:18.059Z",
                    "description" : "21 Plus 3",
                    "transactionId" : "668595562684926787"
                  },
                  {
                    "code" : "SBJ_AnyPair",
                    "stake" : 1000,
                    "payout" : 0,
                    "placedOn" : "2022-11-18T05:39:18.059Z",
                    "description" : "Any Pair",
                    "transactionId" : "668595562684926787"
                  },
                  {
                    "code" : "SBJ_BustIt",
                    "stake" : 1000,
                    "payout" : 0,
                    "placedOn" : "2022-11-18T05:39:18.059Z",
                    "description" : "Bust It",
                    "transactionId" : "668595562684926787"
                  },
                  {
                    "code" : "SBJ_Hot3",
                    "stake" : 1000,
                    "payout" : 5000,
                    "placedOn" : "2022-11-18T05:39:18.059Z",
                    "description" : "Hot 3",
                    "transactionId" : "668595562684926787"
                  },
                  {
                    "code" : "SBJ_Main",
                    "stake" : 4000,
                    "payout" : 8000,
                    "placedOn" : "2022-11-18T05:39:18.059Z",
                    "description" : "Main Bet",
                    "transactionId" : "668595562684926787"
                  }
                ],
                "configOverlays" : [
                  "qr3cg7gl7misgjdf"
                ],
                "playMode" : "RealMoney",
                "hands" : {
                  "hand1" : {
                    "cards" : [
                      "9H",
                      "AC"
                    ],
                    "decisions" : [
                      {
                        "type" : "Stand"
                      }
                    ],
                    "outcome" : "Win",
                    "score" : 20
                  }
                },
                "channel" : "desktop",
                "os" : "macOS",
                "device" : "Desktop",
                "skinId" : "5",
                "brandId" : "5"
              }
            ],
            "result" : {
              "dealtToPlayer" : [
                "9H",
                "AC",
                "4C",
                "KH"
              ],
              "dealerHand" : {
                "score" : 19,
                "cards" : [
                  "AH",
                  "8H"
                ]
              },
              "wonSideBets" : [
                {
                  "code" : "SBJ_Hot3",
                  "combination" : "SBJ_UNSUITED_21"
                }
              ]
            },
            "wager" : 8000,
            "payout" : 13000,
            "transactionKey" : "5ff9dda25e2cf619dacb9fb8999732f1"
          }]),
          desc: '게임 상세 데이터',
          required: true
        },
      }
    };
  },
  mounted() {
    const host = process.env.VUE_APP_API_SERVER_URL
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }
     */
    this.apiUrl = host + 'detail/check'

    // 전송값 설정
    this.response = '----------------------------------------------------------------------------\n' +
        '[사이트 응답값 (code가 0이 아닌 경우 3번 재전송 합니다.)]\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        ' "code": 0\n' +
        '}'
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="card-title"><span className="badge bg-success">콜백 URL</span> {{ callbackUrl }}</div>
            <div className="card-title-desc">{{ $t('text.api_user_balance_callback_desc')}}</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="false"
                      :require-ip-address="false" :require-request-key="false" :method="'run2'"/>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
